import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import {
  actionTypes as entitiesActionTypes,
  fetchEntities,
  maybeFetchOntologies,
  maybeFetchTagSets,
} from 'actions/entities';
import { toggleSupportModal } from 'actions/ui';
import {
  fetchViewFacet,
  maybeFetchTimeSeriesVisualizationsAggregates,
  maybeFetchViews,
  actionTypes as viewActionTypes,
} from 'actions/view';
import { loadingStateSelector } from 'reducers/ui';
import { isComparativeViewFacet, isFacetEmptySelector } from 'selectors/facet';
import { currentViewSelector, viewFacetSelector } from 'selectors/view';

import V2DashboardBenchmark from 'components/customer/analysis/benchmark/V2DashboardBenchmark';
import V2Dashboard from 'components/customer/analysis/dashboard/V2Dashboard';
import V2DashboardExplore from 'components/customer/analysis/explore/V2DashboardExplore';
import V2DashboardSearch from 'components/customer/analysis/search/V2DashboardSearch';
import BeatingLoader from 'components/ui/BeatingLoader';
import { EmptyAnalysisDashboardPage } from 'components/ui/EmptyDataPage';
import LazyLoadedContainer from 'components/ui/layout/LazyLoadedContainer';
import { PageLayout } from 'components/ui/layout/Page';
import { FacetHelperTooltip } from 'components/ui/navigation/FacetNavbar';
import NavigationTabs from 'components/ui/navigation/NavigationTabs';

import ScrollToTop from 'utils/browser';

import * as svars from 'assets/style/variables';

const DASHBOARD_PANES = ['search', 'overview', 'explore', 'benchmark'];

const loadingSelector = loadingStateSelector([
  viewActionTypes.FETCH_VIEWS_REQUEST,
  viewActionTypes.FETCH_ONE_VIEW_FACET_REQUEST,
  entitiesActionTypes.FETCH_TAG_SETS_REQUEST,
  entitiesActionTypes.FETCH_FACET_ENTITIES_REQUEST,
  entitiesActionTypes.FETCH_PRODUCT_HIERARCHIES_REQUEST,
  entitiesActionTypes.FETCH_ONTOLOGIES_REQUEST,
]);

const getAnalysisTabs = (viewFacet) => {
  const isDisabled = !viewFacet;
  return [
    {
      content: t`overview`,
      to: isDisabled ? null : `/facets/analyze/${viewFacet.id}/overview`,
      disabled: isDisabled,
    },
    {
      content: t`exploration`,
      to: isDisabled ? null : `/facets/analyze/${viewFacet.id}/explore`,
      disabled: isDisabled,
    },
    {
      content: t`benchmark`,
      to: isDisabled ? null : `/facets/analyze/${viewFacet.id}/benchmark`,
      disabled: isDisabled || !isComparativeViewFacet(viewFacet),
    },
    {
      content: t`search`,
      to: isDisabled ? null : `/facets/analyze/${viewFacet.id}/search`,
      disabled: isDisabled,
    },
  ];
};

function ViewFacetAnalysisPage() {
  const { viewFacetId, dashboardPane } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const isFacetEmpty = useSelector(isFacetEmptySelector);
  const viewFacet = useSelector(viewFacetSelector);
  const currentView = useSelector(currentViewSelector);
  const isLoading = useSelector(loadingSelector);

  const panes = useMemo(() => getAnalysisTabs(viewFacet), [viewFacet]);
  useEffect(() => {
    dispatch(maybeFetchViews());
    dispatch(maybeFetchTagSets());
  }, []);
  useEffect(() => {
    dispatch(maybeFetchOntologies(viewFacetId));
    dispatch(fetchViewFacet(viewFacetId, true));
    dispatch(fetchEntities(viewFacetId));
    dispatch(maybeFetchTimeSeriesVisualizationsAggregates(viewFacetId, 'kpi'));
  }, [viewFacetId]);

  const onMaybeFetchTimeSeriesVisualizationsAggregates = useCallback(
    (facetId, aggregateName) =>
      dispatch(
        maybeFetchTimeSeriesVisualizationsAggregates(facetId, aggregateName)
      ),
    [viewFacetId]
  );
  const onToggleSupportModal = useCallback(() => {
    dispatch(toggleSupportModal());
  }, []);
  const locationQuery = useMemo(() => {
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          params[key].push(value);
        } else {
          params[key] = [params[key], value];
        }
      } else {
        params[key] = value;
      }
    }
    return params;
  }, [searchParams]);
  const commonProps = {
    viewFacet,
    locationQuery,
    maybeFetchTimeSeriesVisualizationsAggregates:
      onMaybeFetchTimeSeriesVisualizationsAggregates,
  };
  if (!DASHBOARD_PANES.includes(dashboardPane)) {
    return <Navigate to={`/facets/analyze/${viewFacetId}/overview`} />;
  }
  if (
    !currentView ||
    isLoading ||
    // Check equality of loaded view facet and view facet id extracted from route
    // to make sure store is synchronized before displaying the analysis
    viewFacet?.id !== viewFacetId
  )
    return <BeatingLoader absolute />;
  const fullWidth = dashboardPane === 'search';
  return (
    <>
      <NavigationTabs panes={panes}>
        <FacetHelperTooltip />
      </NavigationTabs>
      <PageLayout
        style={{
          padding: fullWidth ? 0 : `0 0 ${svars.spaceMedium} 0`,
        }}
        fullWidth={fullWidth}
      >
        {(isFacetEmpty && (
          <EmptyAnalysisDashboardPage
            viewFacetId={viewFacetId}
            onToggleSupportModal={onToggleSupportModal}
          />
        )) || (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <ScrollToTop />
            <LazyLoadedContainer hidden={dashboardPane !== 'overview'}>
              <V2Dashboard
                key={`dashboardPane-${viewFacetId}-overview`}
                hidden={dashboardPane !== 'overview'}
                {...commonProps}
              />
            </LazyLoadedContainer>
            <LazyLoadedContainer hidden={dashboardPane !== 'explore'}>
              <V2DashboardExplore
                key={`dashboardPane-${viewFacetId}-explore`}
                hidden={dashboardPane !== 'explore'}
                {...commonProps}
              />
            </LazyLoadedContainer>
            <LazyLoadedContainer hidden={dashboardPane !== 'benchmark'}>
              <V2DashboardBenchmark
                key={`dashboardPane-${viewFacetId}-benchmark`}
                hidden={dashboardPane !== 'benchmark'}
                onToggleSupportModal={onToggleSupportModal}
                {...commonProps}
              />
            </LazyLoadedContainer>
            <LazyLoadedContainer hidden={dashboardPane !== 'search'}>
              <V2DashboardSearch
                key={`dashboardPane-${viewFacetId}-search`}
                hidden={dashboardPane !== 'search'}
                {...commonProps}
              />
            </LazyLoadedContainer>
          </div>
        )}
      </PageLayout>
    </>
  );
}

ViewFacetAnalysisPage.propTypes = {};

ViewFacetAnalysisPage.defaultProps = {};

export default ViewFacetAnalysisPage;
