import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { t } from '@lingui/macro';
import { Form, Grid } from 'semantic-ui-react';

import { actionTypes, adminLogAs } from 'actions/user';
import { createLoadingSelector } from 'reducers/ui';

import Header from 'components/ui/Header';
import { MediumPaddedSegment } from 'components/ui/Segment';
import { ButtonAccent } from 'components/ui/button';
import { TextInput } from 'components/ui/inputs/TextInput';
import { PageLayout } from 'components/ui/layout/Page';

import * as svars from 'assets/style/variables';

const loadingSelector = createLoadingSelector([
  actionTypes.ADMIN_LOG_AS_REQUEST,
]);

function AdminSupportPage() {
  const [username, setUsername] = useState('');
  const [logAsError, setLogAsError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(loadingSelector);
  const onLogAs = useCallback(() => {
    if (!loading && username && username.trim()) {
      dispatch(adminLogAs(username.trim()))
        .then(() => {
          setLogAsError(false);
          navigate(0);
        })
        .catch(() => setLogAsError(true));
    }
  }, [loading, username]);

  return (
    <PageLayout>
      <MediumPaddedSegment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>Log in as ...</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row as={Form}>
            <Grid.Column width={6}>
              <TextInput
                fluid
                autoFocus
                id="Username"
                icon="user"
                iconPosition="left"
                onChange={(_, { value }) => setUsername(value)}
                placeholder={t`username`}
                value={username}
              />
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <ButtonAccent onClick={onLogAs} loading={loading}>
                Log as
              </ButtonAccent>
            </Grid.Column>
            <Grid.Column
              width={4}
              style={{ color: svars.colorDanger }}
              verticalAlign="middle"
            >
              {logAsError ? 'Unauthorized' : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </MediumPaddedSegment>
    </PageLayout>
  );
}

AdminSupportPage.propTypes = {};

AdminSupportPage.defaultProps = {};

export default AdminSupportPage;
