import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import SidePane from 'components/ui/panels/SidePane';
import PredictionTable from 'components/ui/prediction/table';

import {
  colorPrimary,
  fontSizeLarge,
  spaceMedium,
} from 'assets/style/variables';

const TextAnalyzed = styled.div`
  font-size: ${fontSizeLarge};
  color: ${colorPrimary};
`;

function ValidationLiveSidePane({ chunks, predictions, onClose, visible }) {
  return (
    <SidePane
      dimmed={0}
      onToggle={onClose}
      animation="push"
      direction="right"
      width="very wide"
      visible={visible}
    >
      <SidePane.Header
        title="Live Page"
        onToggle={onClose}
        style={{ width: '100%' }}
      />
      <SidePane.Body>
        {chunks &&
          chunks.map((chunk, chunkIndex) => (
            <Grid.Row
              columns={2}
              key={`livePreds-preds-${chunkIndex}`} // eslint-disable-line
              style={{ margin: spaceMedium }}
            >
              <Grid.Column width={16}>
                <TextAnalyzed style={{ marginBottom: spaceMedium }}>
                  {chunk}
                </TextAnalyzed>
              </Grid.Column>
              {Object.entries(predictions).map(
                ([ontologyName, predictionsAllChunks], it0) => (
                  <Grid.Column
                    key={`livePreds-table-${ontologyName}`}
                    width={8}
                  >
                    <PredictionTable
                      predictions={predictionsAllChunks[chunkIndex]}
                      tablePosition={it0}
                      title={ontologyName}
                      withVocabulary
                    />
                  </Grid.Column>
                )
              )}
            </Grid.Row>
          ))}
      </SidePane.Body>
    </SidePane>
  );
}

ValidationLiveSidePane.propTypes = {
  chunks: PropTypes.instanceOf(Array).isRequired,
  predictions: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ValidationLiveSidePane;
