import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { actionTypes as viewActionTypes } from 'actions/view';
import {
  useGroupColorFormatter,
  useProductHierarchyGroupLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { createLoadingSelector } from 'reducers/ui';
import { entityLabelFormatterSelector } from 'selectors/entities';
import { isComparativeViewFacet } from 'selectors/facet';
import { aggregatesSelector } from 'selectors/view';

import CompetitivePositioningChart from 'components/customer/visualization/CompetitivePositioningChart/CompetitivePositioningChart';
import DashboardSeparator from 'components/ui/DashboardSeparator';
import { EmptyAnalysisDashboardPage } from 'components/ui/EmptyDataPage';
import { LargeSpacePaddedRow, SmallSpacePaddedGrid } from 'components/ui/Grid';
import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';
import emptyDashboardUrl from 'components/ui/svg/undraw_circles_y7s2.svg';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import BenchmarkExploration from './BenchmarkExploration';
import BenchmarkHeader from './BenchmarkHeader';
import BenchmarkMultiChart, { TS_DISPLAY_OPTIONS } from './BenchmarkMultiChart';
import EmptyBenchmark, { getBenchmarkKpis } from './EmptyBenchmark';

const POSITIONING_DISPLAY_OPTIONS = [
  { key: 1, value: 'bottom', text: 'Bottom' },
  { key: 2, value: 'top', text: 'Top' },
];

const viewFacetAggregatesLoadingSelector = createLoadingSelector([
  viewActionTypes.FETCH_TIME_SERIES_VISUALIZATIONS_REQUEST,
]);

function V2DashboardBenchmark({
  viewFacet,
  // groupColorFormatter,
  maybeFetchTimeSeriesVisualizationsAggregates,
  hidden,
  onToggleSupportModal,
}) {
  // Benchmark positioning state - one of `aggregated` or `evolution`
  const [benchmarkPositioning, setBenchmarkPositioning] =
    useState('aggregated');
  // Benchmark positioning state for a specific concept - one of `aggregated` or `evolution`
  const [benchmarkConceptPositioning, setBenchmarkConceptPositioning] =
    useState('aggregated');
  const [positioningDisplayOption, setPositioningDisplayOption] =
    useState('top');
  // Alternative time series display option
  const [
    alternativeTimeSeriesDisplayOptionValue,
    setAlternativeTimeSeriesDisplayOptionValue,
  ] = useState(TS_DISPLAY_OPTIONS[0].value);
  // Alternative time series display option
  const [
    conceptAlternativeTimeSeriesDisplayOptionValue,
    setConceptAlternativeTimeSeriesDisplayOptionValue,
  ] = useState(TS_DISPLAY_OPTIONS[0].value);

  const {
    timeSeries,
    competitionTimeSeries,
    competitionConceptKpis,
    kpis,
    conceptKpis,
    competitionKpis,
  } = useSelector(aggregatesSelector);
  const entityLabelFormatter = useSelector(entityLabelFormatterSelector);
  const groupColorFormatter = useGroupColorFormatter();
  const viewFacetAggregatesIsLoading = useSelector(
    viewFacetAggregatesLoadingSelector
  );

  useEffect(() => {
    maybeFetchTimeSeriesVisualizationsAggregates(viewFacet.id, 'concept_kpi');
    maybeFetchTimeSeriesVisualizationsAggregates(viewFacet.id, 'ts');
    maybeFetchTimeSeriesVisualizationsAggregates(
      viewFacet.id,
      'competition_kpi'
    );
    maybeFetchTimeSeriesVisualizationsAggregates(
      viewFacet.id,
      'competition_concept_kpi'
    );
    maybeFetchTimeSeriesVisualizationsAggregates(
      viewFacet.id,
      'competition_ts'
    );
  }, []);

  const onSetBenchmarkConceptPositioning = useCallback(
    (_, { value }) => setBenchmarkConceptPositioning(value),
    []
  );

  const {
    differentialSentiment,
    differentialSentimentIncrease,
    comparativeGroupsNChunks,
    comparativeGroupsNChunksIncrease,
  } = useMemo(
    () => getBenchmarkKpis(viewFacet, kpis, competitionKpis),
    [viewFacet, kpis, competitionKpis]
  );

  const hierarchyGroupLabelFormatter = useProductHierarchyGroupLabelFormatter();

  const onBenchmarkConceptPositioningChange = useCallback(
    (_, { value }) => setBenchmarkPositioning(value),
    []
  );
  const onPositioningDisplayOptionChange = useCallback(
    (_, { value }) => setPositioningDisplayOption(value),
    []
  );

  if (hidden) return null;
  if (!isComparativeViewFacet(viewFacet))
    return <EmptyBenchmark viewFacetId={viewFacet?.id} />;
  if (
    competitionKpis !== undefined &&
    (!comparativeGroupsNChunks || comparativeGroupsNChunks === '-')
  ) {
    return (
      <EmptyAnalysisDashboardPage
        illustrationUrl={emptyDashboardUrl}
        headerText={t`dashboard-benchmark.no-data-in-competitive-groups`}
        subHeaderContent={t`dashboard-benchmark.check-competitive-groups`}
        viewFacetId={viewFacet.id}
        onToggleSupportModal={onToggleSupportModal}
      />
    );
  }

  return (
    <SmallSpacePaddedGrid>
      <BenchmarkHeader
        viewFacet={viewFacet}
        differentialSentiment={differentialSentiment}
        differentialSentimentIncrease={differentialSentimentIncrease}
        comparativeGroupsNChunks={comparativeGroupsNChunks}
        comparativeGroupsNChunksIncrease={comparativeGroupsNChunksIncrease}
        isLoading={viewFacetAggregatesIsLoading}
      />
      <DashboardSeparator />
      <LargeSpacePaddedRow style={{ width: '100%' }} centered>
        <Grid.Column stretched width={8}>
          <Segment>
            <div>
              <Header>
                <Trans id="dashboard-benchmark.competitive-positioning" />
              </Header>
              <BenchmarkMultiChart
                timeSeriesDisplayOptions={TS_DISPLAY_OPTIONS}
                chartId="benchmark-multi-all"
                chartTitle={t`dashboard-benchmark.competitive-positioning`}
                loading={viewFacetAggregatesIsLoading}
                benchmarkConceptPositioning={benchmarkPositioning}
                conceptAlternativeTimeSeriesDisplayOptionValue={
                  alternativeTimeSeriesDisplayOptionValue
                }
                onBenchmarkConceptPositioningChange={
                  onBenchmarkConceptPositioningChange
                }
                onDisplayOptionValueChange={
                  setAlternativeTimeSeriesDisplayOptionValue
                }
                baseLabel={hierarchyGroupLabelFormatter(
                  viewFacet.base_product_hierarchy_group.id
                )}
                baseColor={groupColorFormatter(
                  viewFacet.base_product_hierarchy_group.id
                )}
                baseTimeSeries={timeSeries ? timeSeries.monthly : []}
                competitionTimeSeries={
                  competitionTimeSeries ? competitionTimeSeries.monthly : {}
                }
                highlightScatterData={
                  kpis && kpis.kpis ? kpis.kpis[0] : undefined
                }
                scatterData={competitionKpis ? competitionKpis.kpis : undefined}
                labelFormatter={hierarchyGroupLabelFormatter}
                colorFormatter={groupColorFormatter}
              />
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column stretched width={8}>
          <Segment>
            <Header>
              <Header.Content style={{ paddingBottom: svars.spaceNormal }}>
                <Trans id="dashboard-benchmark.performance-compared-to-competition" />
              </Header.Content>
              <Header.Subheader
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Trans id="dashboard-benchmark.ordering-based-on-volume-sentiment" />
                <StyledDropdown
                  small="true"
                  placeholder="Choose status"
                  selection
                  options={POSITIONING_DISPLAY_OPTIONS}
                  onChange={onPositioningDisplayOptionChange}
                  value={positioningDisplayOption}
                />
              </Header.Subheader>
            </Header>
            <CompetitivePositioningChart
              conceptData={
                competitionConceptKpis?.[positioningDisplayOption] || undefined
              }
              competitionConceptKpis={competitionConceptKpis?.kpis || {}}
              conceptKpis={conceptKpis}
              entityLabelFormatter={entityLabelFormatter}
              height={svars.chartHeight + 20}
              loading={viewFacetAggregatesIsLoading}
            />
          </Segment>
        </Grid.Column>
      </LargeSpacePaddedRow>
      <BenchmarkExploration
        viewFacet={viewFacet}
        benchmarkConceptPositioning={benchmarkConceptPositioning}
        conceptAlternativeTimeSeriesDisplayOptionValue={
          conceptAlternativeTimeSeriesDisplayOptionValue
        }
        setConceptAlternativeTimeSeriesDisplayOptionValue={
          setConceptAlternativeTimeSeriesDisplayOptionValue
        }
        setBenchmarkConceptPositioning={onSetBenchmarkConceptPositioning}
      />
    </SmallSpacePaddedGrid>
  );
}

V2DashboardBenchmark.propTypes = {
  viewFacet: commonPropTypes.viewFacet.isRequired,
  maybeFetchTimeSeriesVisualizationsAggregates: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  onToggleSupportModal: PropTypes.func.isRequired,
};

V2DashboardBenchmark.defaultProps = {
  hidden: false,
};

export default V2DashboardBenchmark;
