import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isComparativeViewFacet } from 'selectors/facet';

import { RouterLinkV2 } from 'components/ui/Link';

export function getBenchmarkKpis(viewFacet, kpis, competitionKpis) {
  let differentialSentiment = '-';
  let differentialSentimentIncrease = '-';
  let comparativeGroupsNChunks = '-';
  let comparativeGroupsNChunksIncrease = '-';
  const isComparative = isComparativeViewFacet(viewFacet);
  if (isComparative && competitionKpis) {
    if (competitionKpis.benchmarkKpis && competitionKpis.benchmarkKpis.length) {
      comparativeGroupsNChunks =
        competitionKpis.benchmarkKpis[0].competition_n_chunks;
      differentialSentiment =
        competitionKpis.benchmarkKpis[0].average_sentiment_differential;
    }
    if (competitionKpis.increases) {
      comparativeGroupsNChunksIncrease =
        competitionKpis.increases['1M'][0].n_chunks;
      if (
        kpis &&
        kpis.increases &&
        kpis.increases['1M'] &&
        competitionKpis.increases['1M']
      ) {
        differentialSentimentIncrease =
          kpis.increases['1M'][0].average_sentiment -
          competitionKpis.increases['1M'][0].average_sentiment;
      }
    }
  }
  return {
    differentialSentiment,
    differentialSentimentIncrease,
    comparativeGroupsNChunks,
    comparativeGroupsNChunksIncrease,
  };
}

const EmptyBenchmarkContaioer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
`;

function EmptyBenchmark({ viewFacetId }) {
  return (
    <EmptyBenchmarkContaioer>
      <Trans id="no-data!" />
      <RouterLinkV2 to={`/facets/analyze/${viewFacetId}/overview`}>
        <Trans id="back-to-dashboard" />
      </RouterLinkV2>
      <RouterLinkV2 to="/facets">
        <Trans id="modify-analysis" />
      </RouterLinkV2>
    </EmptyBenchmarkContaioer>
  );
}

EmptyBenchmark.propTypes = {
  viewFacetId: PropTypes.string.isRequired,
};

export default EmptyBenchmark;
