import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Form, Grid, Label } from 'semantic-ui-react';

import { fetchAdminEntities } from 'actions/entities';
import {
  fetchTaskControllers,
  fetchTasks,
  actionTypes as operationsActionTypes,
  revokeTaskController,
  runTaskController,
  setController,
  setControllerModule,
  setTaskComment,
  setTaskParameters,
} from 'actions/operations';
import { formatDate } from 'reducers/locale';
import {
  lastUpdateDateSelector,
  tasksLoadingSelector,
} from 'reducers/operations';
import { createLoadingSelector } from 'reducers/ui';
import { adminEntitiesSelector } from 'selectors/entities';

import moment from 'moment';

import CopyToClipboard from 'components/ui/CopyToClipboard';
import Header from 'components/ui/Header';
import Segment, { MediumPaddedSegment } from 'components/ui/Segment';
import { LimitedTextCell } from 'components/ui/Text';
import {
  ButtonAccent,
  ButtonDanger,
  ButtonSecondaryAccent,
} from 'components/ui/button';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import HoverableIcon, {
  AnalyticsAwareHoverableIconButtonWithTooltip,
} from 'components/ui/icon/HoverableIcon';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import { SelectBox, StyledDropdown } from 'components/ui/inputs/Dropdown';
import { ResettableTextInput, TextInput } from 'components/ui/inputs/TextInput';
import { PageLayout } from 'components/ui/layout/Page';
import ReactTableHeader from 'components/ui/table/ReactTableHeader';
import ReactTable from 'components/ui/table/ReactTableUi';
import {
  CopyableTextCell,
  DateTimeCell,
  LabelCell,
  ReactTableTextCell,
  SerializedCopyableTextCell,
} from 'components/ui/table/cells/ReactTableCell';

import commonPropTypes from 'utils/commonPropTypes';
import { numberFormatter } from 'utils/formatter';
import useInterval, { useDebounce } from 'utils/hooks';

import * as svars from 'assets/style/variables';

const DEFAULT_SORTED = [{ id: 'create_date', desc: true }];

export function ControllerAndCommentCell({
  padded,
  value,
  style,
  row: { original: { comment } = {} },
}) {
  return (
    <LimitedTextCell
      style={{
        margin: '0',
        paddingRight: padded ? svars.spaceNormalLarge : 0,
        width: '100%',
        display: 'block',
        ...style,
      }}
    >
      {value}
      {comment ? (
        <CopyToClipboard
          text={comment}
          iconName="linkify"
          style={{
            color: svars.accentColor,
            fontWeight: svars.fontWeightSemiBold,
          }}
        >
          {comment}
        </CopyToClipboard>
      ) : null}
    </LimitedTextCell>
  );
}

ControllerAndCommentCell.propTypes = {
  padded: PropTypes.bool,
  style: commonPropTypes.style,
  row: PropTypes.shape({
    original: PropTypes.shape({ comment: PropTypes.string }),
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};
ControllerAndCommentCell.defaultProps = {
  style: {},
  value: null,
  row: {},
  padded: false,
};

function DatetimeDifferentialCell({
  value,
  row: {
    original: { start },
  },
}) {
  return (
    <ReactTableTextCell
      value={
        value
          ? numberFormatter(
              moment.duration(moment(value).diff(moment(start))).asMinutes()
            )
          : '-'
      }
    />
  );
}

DatetimeDifferentialCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      start: PropTypes.string,
    }),
  }),
  value: PropTypes.string,
};
DatetimeDifferentialCell.defaultProps = {
  row: { original: {} },
  value: null,
};

function ProductionOrDryRunCell({ value }) {
  return (
    <LabelCell
      color={value ? 'blue' : 'red'}
      value={value ? 'DRY' : 'PROD'}
      basic
    />
  );
}

ProductionOrDryRunCell.propTypes = {
  value: PropTypes.bool,
};

ProductionOrDryRunCell.defaultProps = {
  value: false,
};

function AdminActionCell({
  onReloadTaskClick,
  onRevokeTaskClick,
  controllerRunIsLoading,
  row: { original },
}) {
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <HoverableIcon
        large="true"
        style={{
          color: svars.colorWarning,
          marginRight: svars.spaceNormalLarge,
        }}
        name="stop circle outline"
        onClick={(event) => {
          event.stopPropagation();
          onRevokeTaskClick(original.celery_id);
        }}
        disabled={
          controllerRunIsLoading ||
          !['queued', 'running'].includes(original.status) ||
          original.controller_name !== 'Run a crawler'
        }
      />

      <HoverableIcon
        large="true"
        style={{ paddingRight: svars.spaceMediumLarge }}
        name="file alternate"
        onClick={(event) => {
          event.stopPropagation();
          window.open(original.report, '_blank').focus();
        }}
        disabled={!original.report}
      />

      <HoverableIcon
        size="large"
        style={{ paddingRight: svars.spaceMediumLarge }}
        name="chart area"
        onClick={(event) => {
          event.stopPropagation();
          window.open(original.monitoring, '_blank').focus();
        }}
        disabled={!original.monitoring}
      />
      <HoverableIcon
        size="large"
        style={{ paddingRight: svars.spaceMediumLarge }}
        name="redo"
        onClick={(event) => {
          event.stopPropagation();
          onReloadTaskClick(original);
        }}
      />
    </span>
  );
}

AdminActionCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      controller_name: PropTypes.string,
      status: PropTypes.string,
      celery_id: PropTypes.string,
      report: PropTypes.string,
      monitoring: PropTypes.string,
    }),
  }),
  onReloadTaskClick: PropTypes.func.isRequired,
  onRevokeTaskClick: PropTypes.func.isRequired,
  controllerRunIsLoading: PropTypes.func.isRequired,
};
AdminActionCell.defaultProps = {
  row: { original: {} },
};

const getAdminActionCell = (
  onReloadTaskClick,
  onRevokeTaskClick,
  controllerRunIsLoading
) =>
  function Component(props) {
    return (
      <AdminActionCell
        onReloadTaskClick={onReloadTaskClick}
        onRevokeTaskClick={onRevokeTaskClick}
        controllerRunIsLoading={controllerRunIsLoading}
        {...props}
      />
    );
  };

const taskTableColumns = [
  {
    Header: () => (
      <ReactTableHeader small helpMessage="Id of the task (celery).">
        Task id
      </ReactTableHeader>
    ),
    minWidth: 50,
    maxWidth: 50,
    id: 'celery_id',
    Cell: CopyableTextCell,
    accessor: 'celery_id',
    centered: true,
  },
  {
    id: 'dry_run',
    minWidth: 60,
    maxWidth: 60,
    accessor: 'dry_run',
    centered: true,
    Cell: ProductionOrDryRunCell,
  },
  {
    Header: () => (
      <ReactTableHeader
        small
        helpMessage="Name of the controller used in this task"
      >
        Controller
      </ReactTableHeader>
    ),
    id: 'controller_name',
    Cell: ControllerAndCommentCell,
    width: 200,
    maxWidth: 350,

    accessor: 'controller_name',
    centered: false,
  },
  {
    id: 'status',
    Cell: LabelCell.getLabelCell(
      (value) =>
        (value === 'error' && 'red') ||
        (value === 'done' && 'green') ||
        (value === 'running' && 'purple') ||
        (value === 'revoked' && 'black') ||
        'grey'
    ),
    minWidth: 70,
    maxWidth: 70,
    Header: () => (
      <ReactTableHeader small title="Status" helpMessage="Status of the task" />
    ),
    accessor: 'status',
    centered: true,
  },
  {
    id: 'create_date',
    width: 90,
    maxWidth: 90,
    Cell: DateTimeCell,
    Header: () => (
      <ReactTableHeader small helpMessage="Task's date of creation">
        Create
      </ReactTableHeader>
    ),
    accessor: 'create_date',
    centered: true,
  },
  {
    id: 'start',
    width: 90,
    maxWidth: 90,
    Cell: DateTimeCell,
    Header: () => (
      <ReactTableHeader small title="Start" helpMessage="Task start date" />
    ),
    accessor: 'start',
    centered: true,
  },
  {
    id: 'end',
    width: 80,
    maxWidth: 80,
    Cell: DatetimeDifferentialCell,

    Header: () => (
      <ReactTableHeader small helpMessage="Task duration">
        Duration (min)
      </ReactTableHeader>
    ),
    accessor: 'end',
    centered: true,
  },

  {
    id: 'parameters',
    minWidth: 60,
    maxWidth: 60,
    Header: () => (
      <ReactTableHeader small helpMessage="Parameters used for this task">
        Params
      </ReactTableHeader>
    ),
    Cell: SerializedCopyableTextCell,

    accessor: 'parameters',
  },

  {
    id: 'report',
    minWidth: 60,
    maxWidth: 60,
    Cell: SerializedCopyableTextCell,
    Header: () => (
      <ReactTableHeader
        small
        helpMessage="Report of the run (link to drive file)"
      >
        Logs
      </ReactTableHeader>
    ),
    accessor: ({ status, traceback }) => ({
      status,
      traceback,
    }),
    centered: true,
  },
];

const getReloadTaskActionColumn = (
  onReloadTaskClick,
  onRevokeTaskClick,
  controllerRunIsLoading
) => ({
  id: 'parametersReload',
  Header: null,
  Cell: getAdminActionCell(
    onReloadTaskClick,
    onRevokeTaskClick,
    controllerRunIsLoading
  ),
  accessor: 'parameters',
  width: 90,
  maxWidth: 500,
  centered: true,
});

function FormField({
  name,
  dest,
  choices,
  required,
  type,
  help,
  template_url,
  value,
  onChange,
  backgroundColor,
  ...otherProps
}) {
  let component;

  const adminEntities = useSelector(adminEntitiesSelector);
  if (type === 'BOOL_TYPE') {
    component = (
      <Checkbox
        toggle
        checked={value}
        onChange={(e, { checked }) => onChange(checked)}
        required={required}
        placeholder={`${otherProps.default}`}
      />
    );
  } else if (type.includes('MultiChoice')) {
    const [bulkEdit, setBulkEdit] = useState(false);
    const [bulkEditValue, setBulkEditValue] = useState('');
    const regex = /\[(.*?)\]/;
    const identifier = type.match(regex)?.[1];

    if (bulkEdit) {
      component = (
        <span
          style={{
            display: 'inline-flex',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <TextInput
            style={{ minWidth: '60%', maxWidth: '80%' }}
            value={bulkEditValue}
            onChange={(e, data) => setBulkEditValue(data.value)}
            required={required}
            placeholder="Add values in bulk by providing a comma-separated list of ids"
          />
          <AnalyticsAwareHoverableIconButtonWithTooltip
            help="Apply bulk edit"
            name="check"
            size="large"
            style={{ color: svars.colorSuccess }}
            success
            onClick={() => {
              onChange(bulkEditValue.split(',').map((item) => item.trim()));
              setBulkEdit(false);
              setBulkEditValue('');
            }}
          />
        </span>
      );
    } else {
      const options =
        (type.includes('Auth') &&
          (adminEntities?.[identifier] || []).map(
            ({ id, name: entityName, username }) => ({
              key: id,
              value: id,
              text: entityName || username,
            })
          )) ||
        (type.includes('Enum') &&
          choices?.map((item) => ({ key: item, value: item, text: item }))) ||
        [];
      const multiple = !!type.match(/\(multi\)/);

      component = (
        <span style={{ display: 'inline-flex', width: '100%' }}>
          <SelectBox
            style={{ minWidth: '60%', maxWidth: '80%' }}
            multiple={multiple}
            options={options}
            value={value}
            onChange={(e, { value: newValue }) => onChange(newValue)}
          />
          <AnalyticsAwareHoverableIconButtonWithTooltip
            name="box"
            style={{ height: '100%' }}
            onClick={() => setBulkEdit(!bulkEdit)}
          />
        </span>
      );
    }
  } else {
    component = (
      <TextInput
        fluid
        value={value}
        onChange={(e, data) => onChange(data.value)}
        required={required}
        placeholder={otherProps.default}
      />
    );
  }
  return (
    <Form.Field as={Segment} required={required} style={{ backgroundColor }}>
      <Header>
        <span>
          {name} ({dest})
          {required ? (
            <span style={{ color: 'red', margin: '0 5px' }}>*</span>
          ) : null}
          {template_url ? (
            <SecondaryTabButton
              style={{ marginLeft: svars.spaceMedium }}
              onClick={(event) => {
                event.stopPropagation();
                if (template_url) {
                  window.open(template_url, '_blank').focus();
                }
              }}
            >
              See template
            </SecondaryTabButton>
          ) : null}
          <Label basic color="brown" style={{ float: 'right' }}>
            {type}
          </Label>
        </span>
        <Header.Subheader style={{ paddingTop: svars.spaceNormal }}>
          {help}
        </Header.Subheader>
      </Header>
      {component}
    </Form.Field>
  );
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  dest: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  help: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
  template_url: PropTypes.string,
  backgroundColor: PropTypes.string,
};

FormField.defaultProps = {
  choices: null,
  required: false,
  help: '',
  value: '',
  template_url: null,
  backgroundColor: '#fff',
};

const TASK_STATUSES_TO_REFRESH = ['running', 'queued'];

const renderSubText = (subTextElement, i) => (
  <div key={i} style={{ whiteSpace: 'pre-wrap' }}>
    {subTextElement}
  </div>
);
const makeDropdownContent = (text, subText, module, key) => (
  <div key={key}>
    <div
      style={{
        fontSize: svars.fontSizeLarge,
        paddingBottom: svars.spaceSmall,
      }}
    >
      {text} {module ? `(${module})` : null}
    </div>
    <div
      style={{
        lineHeight: 1.2,
        fontSize: '0.9rem',
        fontWeight: svars.fontWeightLight,
      }}
    >
      {subText?.map?.(renderSubText) || subText}
    </div>
  </div>
);

function AdminOperationsPage({
  dispatchFetchTaskControllers,
  dispatchFetchTasks,
  dispatchRunTaskController,
  dispatchRevokeTaskController,
  dispatchSetController,
  dispatchSetTaskParameters,
  dispatchSetControllerModule,
  dispatchSetTaskComment,
  dispatchFetchAdminEntities,
  modules,
  module,
  controllers,
  tasks,
  controller,
  taskParameters,
  taskComment,
  controllerRunIsLoading,
}) {
  const [searchQueryValue, setSearchQueryValue] = useState('');
  const debouncedSearchQueryValue = useDebounce(searchQueryValue, 800);
  const lastUpdateDate = useSelector(lastUpdateDateSelector);
  // Create loading selector for run controller
  const loading = useSelector(tasksLoadingSelector);

  const onSearchChange = useCallback((e, { value }) => {
    setSearchQueryValue(value);
  }, []);
  const onResetSearch = useCallback(() => {
    setSearchQueryValue('');
  }, []);
  useEffect(() => {
    dispatchFetchTaskControllers();
    dispatchFetchAdminEntities();
    dispatchFetchTasks(null, null);
  }, []);
  useEffect(() => {
    dispatchFetchTasks(null, searchQueryValue);
  }, [debouncedSearchQueryValue]);
  const allControllers = useMemo(
    () =>
      Object.entries(controllers).reduce(
        (acc, [localModule, { controllers: localControllers }]) => {
          if (localControllers) {
            return [
              ...acc,
              ...localControllers.map((item) => ({
                ...item,
                module: localModule,
              })),
            ];
          }
          return acc;
        },
        []
      ),
    [controllers]
  );
  const reloadParameters = (originalRow) => {
    // Find controller module first
    const [moduleName, _] = Object.entries(controllers).find(
      ([name, moduleControllers]) =>
        moduleControllers &&
        moduleControllers.controllers.some(
          (moduleController) =>
            moduleController.name === originalRow.controller_name
        )
          ? name
          : null
    );
    dispatchSetControllerModule(null, { value: moduleName });
    dispatchSetController(null, { value: originalRow.controller_name });
    Object.entries(originalRow.parameters).forEach(([name, value]) =>
      dispatchSetTaskParameters(name, value)
    );
    dispatchSetTaskComment(originalRow.comment);
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };
  useInterval(() => {
    if (
      !tasks ||
      tasks.some(({ status }) => TASK_STATUSES_TO_REFRESH.includes(status))
    ) {
      // dispatchFetchTasks accepts id and a searchQueryValue.
      // By passing the searchQueryValue, it will be taken into account by the polling system
      dispatchFetchTasks(null, searchQueryValue);
    }
  }, 15000);
  const commentProps = {
    default: null,
    dest: 'comment',
    help: 'A custom comment you can add to help find a specific task later on',
    name: 'Comment',
    required: false,
    type: `<class 'str'>`,
  };
  const monitoringDomain = tasks?.[0]?.monitoring
    ?.split(':')
    ?.slice(0, -1)
    ?.join(':');

  const netdataMonitoringLink = monitoringDomain
    ? `${monitoringDomain}:19999`
    : null;
  return (
    <PageLayout>
      <MediumPaddedSegment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>Run operational tasks</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8} style={{ maxWidth: '600px' }}>
              <StyledDropdown
                clearable
                search
                selection
                options={modules.map(({ subText, text, ...moduleItem }) => ({
                  ...moduleItem,
                  text,
                  content: makeDropdownContent(text, subText, null, text),
                }))}
                value={module || ''}
                onChange={dispatchSetControllerModule}
                placeholder="Select a category"
              />
            </Grid.Column>
            <Grid.Column width={8} style={{ maxWidth: '600px' }}>
              <StyledDropdown
                search
                selection
                options={(
                  controllers[module]?.controllers || allControllers
                )?.map(({ name, help, module: localModule }) => ({
                  text: name,
                  key: name,
                  value: name,
                  content: makeDropdownContent(name, help, localModule, name),
                }))}
                // disabled={!module}
                value={controller.name || ''}
                onChange={dispatchSetController}
                placeholder="Select a controller"
              />
            </Grid.Column>
          </Grid.Row>
          {Object.keys(controller).length ? (
            <Grid.Row as={MediumPaddedSegment}>
              <Grid.Column width={16} as={Form}>
                <Grid.Row>
                  <Grid.Column width={10}>
                    {controller.help.map((text) => (
                      <p key={`${text}`}>{text}</p>
                    ))}
                  </Grid.Column>
                  {controller.parameters.map((fieldProps) => (
                    <Grid.Column
                      key={`${fieldProps.name}`}
                      style={{ marginTop: svars.spaceMedium }}
                      width={16}
                    >
                      <FormField
                        value={taskParameters[fieldProps.dest] || ''}
                        onChange={(value) =>
                          dispatchSetTaskParameters(fieldProps.dest, value)
                        }
                        {...fieldProps}
                      />
                    </Grid.Column>
                  ))}
                  <Grid.Column
                    key={`${commentProps.name}`}
                    style={{
                      marginTop: svars.spaceMedium,
                    }}
                    width={16}
                  >
                    <FormField
                      value={taskComment || ''}
                      onChange={(value) => dispatchSetTaskComment(value)}
                      backgroundColor={svars.accentColorMedium}
                      {...commentProps}
                    />
                  </Grid.Column>
                  <Grid.Column
                    style={{
                      float: 'right',
                      padding: `${svars.spaceMedium} 0`,
                    }}
                    width={16}
                    textAlign="right"
                  >
                    <ButtonAccent
                      onClick={() =>
                        dispatchRunTaskController(
                          controller.name,
                          taskParameters,
                          true
                        )
                      }
                      style={{ minWidth: '150px' }}
                      disabled={controller.no_dry_run || controllerRunIsLoading}
                    >
                      Dry run
                    </ButtonAccent>
                    <ButtonDanger
                      style={{
                        marginLeft: svars.spaceXLarge,
                        minWidth: '150px',
                      }}
                      onClick={() =>
                        dispatchRunTaskController(
                          controller.name,
                          taskParameters,
                          false
                        )
                      }
                      disabled={controllerRunIsLoading}
                    >
                      Production run
                    </ButtonDanger>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          ) : null}
        </Grid>
      </MediumPaddedSegment>
      <MediumPaddedSegment style={{ margin: 0 }}>
        <Grid>
          <Grid.Row>
            <Grid.Column
              style={{
                flexGrow: 1,
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                maxWidth: '100%',
              }}
            >
              <span
                style={{
                  flexGrow: 1,
                  // maxWidth: '60%',
                  float: 'right',
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <ResettableTextInput
                    placeholder="task_id, task, status, comments, parameters, ..."
                    value={searchQueryValue}
                    onChange={onSearchChange}
                    onReset={onResetSearch}
                    baseIconName="search"
                    style={{
                      flexGrow: 1,
                      maxWidth: svars.inputMaxWidth,
                      marginRight: svars.spaceMedium,
                    }}
                    fluid
                  />
                  {/* <ButtonSecondaryAccent
                    as={Link}
                    href={netdataMonitoringLink}
                    target="_blank"
                    style={{
                      minWidth: '0px',
                      margin: `0 ${svars.spaceMedium}`,
                    }}
                    
                    disabled={!netdataMonitoringLink}
                  >
                    <Icon name="microchip" /> */}
                  <HoverableIcon
                    accent
                    name="microchip"
                    size="large"
                    style={{
                      color: svars.colorPrimary,
                      marginRight: svars.spaceMedium,
                    }}
                    onClick={() => {
                      if (netdataMonitoringLink) {
                        // Open a new tab to the monitoring page
                        window.open(netdataMonitoringLink, '_blank').focus();
                      }
                    }}
                    disabled={!netdataMonitoringLink}
                  />
                </div>

                <span>
                  Last update :{' '}
                  {lastUpdateDate
                    ? formatDate(new Date(lastUpdateDate), 'pp')
                    : '-'}
                  <ButtonSecondaryAccent
                    onClick={() =>
                      !loading && dispatchFetchTasks(null, searchQueryValue)
                    }
                    style={{
                      minWidth: '0px',
                      margin: `0 ${svars.spaceMedium}`,
                    }}
                    icon="refresh"
                    disabled={loading}
                  />
                </span>
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column style={{ minHeight: '50%', maxHeight: '700px' }}>
              <ReactTable
                defaultSorted={DEFAULT_SORTED}
                data={tasks}
                columns={[
                  ...taskTableColumns,
                  getReloadTaskActionColumn(
                    reloadParameters,
                    dispatchRevokeTaskController,
                    controllerRunIsLoading
                  ),
                ]}
                i18nNoDataText="No recent task"
                rowKey="celery_id"
                hoverable
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </MediumPaddedSegment>
    </PageLayout>
  );
}

AdminOperationsPage.propTypes = {
  dispatchFetchTaskControllers: PropTypes.func.isRequired,
  dispatchFetchTasks: PropTypes.func.isRequired,
  dispatchRunTaskController: PropTypes.func.isRequired,
  dispatchRevokeTaskController: PropTypes.func.isRequired,
  dispatchSetController: PropTypes.func.isRequired,
  dispatchSetTaskParameters: PropTypes.func.isRequired,
  dispatchSetControllerModule: PropTypes.func.isRequired,
  dispatchSetTaskComment: PropTypes.func.isRequired,
  dispatchFetchAdminEntities: PropTypes.func.isRequired,
  modules: commonPropTypes.modules.isRequired,
  controllers: commonPropTypes.controllers.isRequired,
  controller: commonPropTypes.controller.isRequired,
  module: PropTypes.string,
  tasks: commonPropTypes.tasks.isRequired,
  taskParameters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  taskComment: PropTypes.string,
  controllerRunIsLoading: PropTypes.bool.isRequired,
};

AdminOperationsPage.defaultProps = { module: null, taskComment: null };

const runControllerLoadingSelector = createLoadingSelector([
  operationsActionTypes.RUN_CONTROLLER_REQUEST,
]);

const mapStateToProps = (state) => ({
  controllers: state.operations.controllers,
  controller: state.operations.controller,
  tasks: state.operations.tasks,
  modules: state.operations.modules,
  module: state.operations.module,
  taskParameters: state.operations.taskParameters,
  taskComment: state.operations.taskComment,
  controllerRunIsLoading: runControllerLoadingSelector(state),
  adminEntities: state.entities.adminEntities,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchTaskControllers: (name, parameters, dryRun) =>
    dispatch(fetchTaskControllers(name, parameters, dryRun)),
  dispatchFetchTasks: (id, queryString) =>
    dispatch(fetchTasks(id, queryString)),
  dispatchRunTaskController: (name, parameters, dryRun) =>
    dispatch(runTaskController(name, parameters, dryRun)),
  dispatchRevokeTaskController: (taskId) =>
    dispatch(revokeTaskController(taskId)),
  dispatchSetControllerModule: (e, data) =>
    dispatch(setControllerModule(e, data)),
  dispatchSetController: (e, data) => dispatch(setController(e, data)),
  dispatchSetTaskParameters: (name, value) =>
    dispatch(setTaskParameters(name, value)),
  dispatchSetTaskComment: (value) => dispatch(setTaskComment(value)),
  dispatchFetchAdminEntities: () => dispatch(fetchAdminEntities()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminOperationsPage);
