import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { api } from 'actions/utils';
import {
  setTextsStatus,
  skipReview,
  updateReviewDisabled,
  validateReview,
} from 'actions/validation';
import {
  reviewTextsStatusSelector,
  selectClassifications,
  textsStatusListSelector,
} from 'reducers/validation';

import ValidateReviewChunk from 'components/admin/validation/ValidateReviewChunk';
import ValidationLiveSidePane from 'components/admin/validation/ValidationLiveSidePane';
import { NoMarginGrid } from 'components/ui/Grid';
import {
  ButtonDanger,
  ButtonSecondaryWarning,
  ButtonSuccess,
} from 'components/ui/button';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const TextAnalyzed = styled.span`
  font-size: ${svars.fontSizeLarge};
`;

const SHORTCUT_CHUNK_STATUS = ['Q', 'D', 'DOS', 'DOPS'];

const addItemContent = (items) =>
  items?.map((item) => ({
    ...item,
    content: (
      <div>
        <div
          style={{
            fontSize: svars.fontSizeLarge,
            paddingBottom: svars.spaceSmall,
          }}
        >
          {item.text}
        </div>
        <div
          style={{
            lineHeight: 1.2,
            fontSize: '0.9rem',
            fontWeight: svars.fontWeightLight,
          }}
        >
          {item.desc &&
            item.desc.map((desc) => (
              <div style={{ whiteSpace: 'pre-wrap' }} key={desc}>
                {desc}
              </div>
            ))}
        </div>
      </div>
    ),
  }));

function ValidateReview({ review }) {
  const dispatch = useDispatch();
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);

  const [predictions, setPredictions] = useState({});
  const [chunks, setChunks] = useState([]);

  const textsStatusList = useSelector(textsStatusListSelector);
  const reviewTextsStatus = useSelector(reviewTextsStatusSelector);

  const fetchData = async (inputText) => {
    api.post('/classify', { text: inputText }).then((response) => {
      setPredictions(response.data.predictions);
      setChunks(response.data.chunks);
    });
  };

  const handleToggleSidePane = useCallback(
    (text) => async () => {
      if (!isSidePaneOpen && text) await fetchData(text);
      setIsSidePaneOpen(!isSidePaneOpen);
    },
    [isSidePaneOpen, setIsSidePaneOpen]
  );

  const onSkipReview = useCallback(
    () => dispatch(skipReview(review.id)),
    [review?.id]
  );

  const onSetTextsStatus = useCallback(
    (iChunk) =>
      (_, { value }) =>
        dispatch(setTextsStatus(iChunk, value)),
    [review?.id]
  );
  const onValidateReview = useCallback(
    () => dispatch(validateReview(review.id)),
    [review?.id]
  );
  const onUpdateReviewDisabled = useCallback(
    () => dispatch(updateReviewDisabled(true, review.id)),
    [review?.id]
  );
  return (
    <NoMarginGrid>
      <Grid.Row textAlign="right">
        <Grid.Column>
          <ButtonSecondaryWarning
            style={{ marginRight: svars.spaceLarge }}
            onClick={onSkipReview}
          >
            Skip
          </ButtonSecondaryWarning>
          <ButtonDanger onClick={onUpdateReviewDisabled} floated="right">
            Disable review
          </ButtonDanger>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header style={{ padding: '1rem 0' }}>
            {review.id} : {review.date && review.date.split('T')[0]} -{' '}
            {review.title}
          </Header>
          {review.texts.reduce((concat, chunk) => `${concat}\n${chunk}`, '')}
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {review.texts.length ? (
          <Grid divided="vertically" style={{ width: '100%' }}>
            {review.texts.map((text, i) => (
              <Grid.Row
                key={`${review.id}-${i}`} // eslint-disable-line
                columns={1}
                style={{
                  padding: '1rem',
                  background:
                    reviewTextsStatus?.[i] === 'Q'
                      ? svars.colorGreyMedium
                      : 'inherit',
                }}
              >
                <Grid.Column width={10}>
                  <TextAnalyzed>
                    <b>{i}. </b>
                    {text}
                  </TextAnalyzed>
                </Grid.Column>
                <ValidationLiveSidePane
                  visible={isSidePaneOpen}
                  chunks={chunks}
                  predictions={predictions}
                  onClose={handleToggleSidePane()}
                />
                <Grid.Column width={6}>
                  <ButtonGroup
                    as={SecondaryTabButton}
                    items={textsStatusList
                      ?.filter(({ value }) =>
                        SHORTCUT_CHUNK_STATUS.includes(value)
                      )
                      ?.map(({ ...item }) => ({
                        ...item,
                        label: item.value,
                      }))}
                    onChange={onSetTextsStatus(i)}
                    value={reviewTextsStatus?.[i]}
                    style={{ fontWeight: 900, paddingBottom: '0.5rem' }}
                  />
                  <StyledDropdown
                    placeholder="Choose status"
                    fluid
                    search
                    selection
                    options={addItemContent(textsStatusList)}
                    onChange={onSetTextsStatus(i)}
                    value={reviewTextsStatus?.[i]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ValidateReviewChunk
                    disabled={reviewTextsStatus?.[i] !== 'Q'}
                    reviewChunkIndex={i}
                    ontologyToPredictions={selectClassifications(review)}
                    displayLiveResults={handleToggleSidePane(text)}
                  />
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        ) : null}
      </Grid.Row>
      <Grid.Row style={{ paddingBottom: svars.spaceXLarge }}>
        <Grid.Column floated="right">
          <ButtonSuccess
            onClick={() => onValidateReview(review.id)}
            floated="right"
          >
            Validate review
          </ButtonSuccess>
        </Grid.Column>
      </Grid.Row>
    </NoMarginGrid>
  );
}

ValidateReview.propTypes = {
  review: commonPropTypes.review.isRequired,
};

export default ValidateReview;
