import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  maybeFetchConceptBasedAggregates,
  actionTypes as viewActionTypes,
} from 'actions/view';
import {
  useConceptLabelFormatter,
  useGroupColorFormatter,
  useProductHierarchyGroupLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { createLoadingSelector } from 'reducers/ui';
import {
  defaultOntologyIdSelector,
  ontologyLabelsItemsSelector,
} from 'selectors/entities';
import {
  aggregatesSelector,
  getConceptAggregatesSelector,
  ontologyTableDataSelector,
} from 'selectors/view';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import {
  LargeSpacePaddedRow,
  MediumSpacePaddedRow,
  SmallSpacePaddedRow,
} from 'components/ui/Grid';
import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import BenchmarkMultiChart from './BenchmarkMultiChart';
import CompetitionConceptTable from './CompetitionConceptTable';
import CompetitionOntologyTable, {
  BenchmarkKpis,
} from './CompetitionOntologyTable';

const SegmentSmallSpacePaddedRow = styled(SmallSpacePaddedRow)`
  &&& {
    border-radius: ${svars.borderRadius};
    min-height: 30vh;
    border: 1px solid ${svars.colorLightGrey};
    box-shadow: ${svars.baseBoxShadow};
    margin: 0 ${svars.spaceMediumLarge};
    padding: 0;
  }
`;

const benchmarkConceptAggregatesLoadingSelector = createLoadingSelector([
  viewActionTypes.FETCH_CONCEPT_AGGREGATES_REQUEST,
  viewActionTypes.FETCH_TIME_SERIES_VISUALIZATIONS_REQUEST,
]);

function BenchmarkExploration({
  viewFacet,
  benchmarkConceptPositioning,
  conceptAlternativeTimeSeriesDisplayOptionValue,
  setConceptAlternativeTimeSeriesDisplayOptionValue,
  setBenchmarkConceptPositioning,
}) {
  const dispatch = useDispatch();
  const ontologiesLabelsItems = useSelector(ontologyLabelsItemsSelector);
  const groupColorFormatter = useGroupColorFormatter();
  const getConceptAggregates = useSelector(getConceptAggregatesSelector);
  const conceptAggregatesIsLoading = useSelector(
    benchmarkConceptAggregatesLoadingSelector
  );
  const { competitionConceptKpis, conceptKpis } =
    useSelector(aggregatesSelector);

  const [selectedConceptId, setSelectedConceptId] = useState(undefined);
  const initialOntologyId = useSelector(
    defaultOntologyIdSelector(selectedConceptId)
  );
  const [ontologyId, setOntologyId] = useState(initialOntologyId);

  const [
    competitionOntologyTableSortedColumn,
    setCompetitionOntologyTableSortedColumn,
  ] = useState('differentialSentiment');

  const competitionOntologyTableData = useSelector(
    ontologyTableDataSelector(ontologyId)
  );

  const hierarchyGroupLabelFormatter = useProductHierarchyGroupLabelFormatter();
  const conceptLabelFormatter = useConceptLabelFormatter();

  const onSetCompetitionOntologyTableSortedColumn = useCallback(
    (e, { value }) => setCompetitionOntologyTableSortedColumn(value),
    [setCompetitionOntologyTableSortedColumn]
  );

  const onSetOntologyId = useCallback(
    (e, { value }) => {
      if (selectedConceptId) {
        setSelectedConceptId(null);
      }
      setOntologyId(value);
    },
    [setOntologyId, selectedConceptId]
  );

  const onSelectOntologyConcept = useCallback(
    (conceptId) => {
      dispatch(
        maybeFetchConceptBasedAggregates(viewFacet.id, conceptId, 'concept_ts')
      );
      dispatch(
        maybeFetchConceptBasedAggregates(
          viewFacet.id,
          conceptId,
          'competition_concept_ts'
        )
      );
      setSelectedConceptId(conceptId);
    },
    [setSelectedConceptId, selectedConceptId]
  );

  let conceptLabel;
  let baseKpi;
  let competitionKpis;
  let competitionOntologyTableItem;
  let conceptTimeSeries;
  let competitionConceptTimeSeries;
  if (selectedConceptId) {
    conceptLabel = conceptLabelFormatter(selectedConceptId);
    // Kpis for the base product hierarchy group
    baseKpi = conceptKpis?.[ontologyId]?.[selectedConceptId]?.[0] || {};
    competitionKpis =
      (competitionConceptKpis?.kpis &&
        competitionConceptKpis?.kpis[selectedConceptId]) ||
      [];

    competitionOntologyTableItem = competitionOntologyTableData.find(
      ({ conceptId }) => conceptId === selectedConceptId
    );

    const conceptAggregates = getConceptAggregates(
      viewFacet.id,
      selectedConceptId
    );
    conceptTimeSeries = conceptAggregates.conceptTimeSeries;
    competitionConceptTimeSeries =
      conceptAggregates.competitionConceptTimeSeries;
  }
  if (!ontologyId) {
    return (
      <LargeSpacePaddedRow>
        <Grid.Column
          width={16}
          textAlign="center"
          style={{ paddingBottom: svars.spaceMedium }}
        >
          <Header>
            <Trans id="dashboard-benchmark.select-an-ontology-to-see-details" />
            .
          </Header>
        </Grid.Column>
        <Grid.Column width={16} textAlign="center">
          <ButtonGroup
            items={ontologiesLabelsItems}
            onChange={onSetOntologyId}
            value={ontologyId}
            centered
          />
        </Grid.Column>
      </LargeSpacePaddedRow>
    );
  }
  return (
    <>
      {ontologiesLabelsItems?.length > 1 ? (
        <MediumSpacePaddedRow>
          <Grid.Column width={16} stretched>
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <span
                style={{
                  fontSize: svars.fontSizeLarge,
                  fontWeight: svars.fontWeightSemiBold,
                  paddingRight: svars.spaceMedium,
                }}
              >
                <Trans id="select-the-ontology" /> :
              </span>

              <StyledDropdown
                selection
                deburr
                options={ontologiesLabelsItems}
                onChange={onSetOntologyId}
                value={ontologyId}
                style={{ width: 'auto' }}
                disabled={ontologiesLabelsItems.length === 1}
              />
            </span>
          </Grid.Column>
        </MediumSpacePaddedRow>
      ) : null}
      <SegmentSmallSpacePaddedRow centered>
        <Grid.Column width={6} stretched style={{ paddingLeft: 0 }}>
          <Segment style={{ padding: 0 }}>
            <CompetitionOntologyTable
              data={competitionOntologyTableData}
              selectedConceptId={selectedConceptId}
              onSelectOntologyConcept={onSelectOntologyConcept}
              tableSortedColumn={competitionOntologyTableSortedColumn}
              onTableSort={onSetCompetitionOntologyTableSortedColumn}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column
          width={10}
          stretched
          style={{ paddingTop: svars.spaceLarge }}
        >
          <Segment vertical textAlign="center">
            {selectedConceptId ? (
              <Grid>
                <MediumSpacePaddedRow centered>
                  <Grid.Column width={10}>
                    <Header
                      style={{
                        fontSize: svars.fontSizeXLarge,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ paddingBottom: svars.spaceMedium }}>
                        {conceptLabel}
                      </span>
                      <Header.Subheader style={{ minWidth: '300px' }}>
                        <BenchmarkKpis
                          {...competitionOntologyTableItem}
                          centered
                        />
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </MediumSpacePaddedRow>
                <MediumSpacePaddedRow centered columns={1}>
                  <Grid.Column
                    style={{ maxHeight: '40vh' }}
                    verticalAlign="middle"
                    textAlign="center"
                    width={16}
                  >
                    <CompetitionConceptTable
                      labelFormatter={hierarchyGroupLabelFormatter}
                      colorFormatter={groupColorFormatter}
                      competitionConceptKpis={competitionKpis}
                      baseKpi={baseKpi}
                      getSearchUrl={(productHierarchyGroupId) =>
                        `/facets/analyze/${
                          viewFacet.id
                        }/search?${createSearchParams({
                          phg: productHierarchyGroupId,
                          concept: selectedConceptId,
                        })}`
                      }
                    />
                  </Grid.Column>
                </MediumSpacePaddedRow>
                <MediumSpacePaddedRow centered columns={1}>
                  <Grid.Column as={Segment} verticalAlign="middle" width={16}>
                    <BenchmarkMultiChart
                      chartId={`benchmark-multi-${selectedConceptId}`}
                      chartTitle={`${t`dashboard-benchmark.competitive-positioning`} - ${conceptLabel}`}
                      loading={conceptAggregatesIsLoading}
                      benchmarkConceptPositioning={benchmarkConceptPositioning}
                      onBenchmarkConceptPositioningChange={
                        setBenchmarkConceptPositioning
                      }
                      onDisplayOptionValueChange={
                        setConceptAlternativeTimeSeriesDisplayOptionValue
                      }
                      conceptAlternativeTimeSeriesDisplayOptionValue={
                        conceptAlternativeTimeSeriesDisplayOptionValue
                      }
                      baseLabel={hierarchyGroupLabelFormatter(
                        viewFacet.base_product_hierarchy_group.id
                      )}
                      baseColor={groupColorFormatter(
                        viewFacet.base_product_hierarchy_group.id
                      )}
                      baseTimeSeries={conceptTimeSeries}
                      competitionTimeSeries={competitionConceptTimeSeries}
                      scatterData={
                        (competitionConceptKpis &&
                          competitionConceptKpis.kpis &&
                          competitionConceptKpis.kpis[selectedConceptId]) ||
                        []
                      }
                      highlightScatterData={
                        conceptKpis?.[ontologyId]?.[selectedConceptId]?.[0]
                      }
                      labelFormatter={hierarchyGroupLabelFormatter}
                      colorFormatter={groupColorFormatter}
                    />
                  </Grid.Column>
                </MediumSpacePaddedRow>
              </Grid>
            ) : (
              <EmptyDataPage
                headerText={t`dashboard-benchmark.explore-benchmark-for-one-category`}
                actionComponent={t`dashboard-benchmark.explore-benchmark-further-explore-select-category`}
                maxHeight="22rem"
              />
            )}
          </Segment>
        </Grid.Column>
      </SegmentSmallSpacePaddedRow>
    </>
  );
}

BenchmarkExploration.propTypes = {
  viewFacet: commonPropTypes.viewFacet,
  benchmarkConceptPositioning: PropTypes.string.isRequired,
  conceptAlternativeTimeSeriesDisplayOptionValue: PropTypes.string.isRequired,
  setConceptAlternativeTimeSeriesDisplayOptionValue: PropTypes.func.isRequired,
  setBenchmarkConceptPositioning: PropTypes.func.isRequired,
};

BenchmarkExploration.defaultProps = {
  viewFacet: undefined,
};

export default BenchmarkExploration;
