import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Dimmer, Loader, Menu, Modal, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { analysisTagSetsItemsSelector } from 'selectors/facet';

import ScatterChartWithExportModal from 'components/customer/visualization/scatter-chart/ScatterChartWithExportModal';
import HelpTooltip from 'components/ui/HelpTooltip';
import NormalSpacePaddedSegment from 'components/ui/Segment';
import {
  AnalyticsAwareButton,
  ButtonTransparentAccent,
} from 'components/ui/button';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import ExportAsImage from 'components/ui/button/export-as/ExportAsImage';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';
import Breadcrumb from 'components/ui/navigation/Breadcrumb';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import ExplorationBreakdownTable from './ExplorationBreakdownTable';

const BreadcrumbContainer = styled.div`
  box-shadow: ${svars.selectedBoxShadow};
`;
const BodyContainer = styled.div`
  padding: ${svars.spaceMedium};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledMenuHeader = styled(Menu.Header)`
  display: inline-flex;
  width: 100%;
  padding: ${svars.spaceNormalLarge} ${svars.spaceMedium};
  font-style: italic;
  color: ${svars.fontColorLighter};
  & i {
    margin-right: ${svars.spaceMedium};
  }
`;

function ExplorationBreakdownModal({
  data,
  onClose,
  labelFormatter,
  modalIsOpen,
  globalAverageSentiment,
  categoryDataKey,
  categoryHeader,
  path,
  title,
  selectItems,
  currentDistribution,
  onDistributionChange,
  onSelectConcept,
  withShareOfExtracts,
}) {
  const [explorationMode, setExplorationMode] = useState('graph');
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const toggleExportModal = useCallback(
    () => setExportModalIsOpen(!exportModalIsOpen),
    [exportModalIsOpen]
  );
  const chartId = `chart-${explorationMode}`;
  let exportName = categoryHeader || '';
  if (path?.[0]?.label) {
    exportName = `${exportName} - ${path[0].label}`;
  }
  const shareOfExtractsProps = useMemo(
    () =>
      withShareOfExtracts
        ? {
            getRadius: svars.getPercentScatterRadius('share_of_extracts'),
            radiusTooltipFieldKey: 'share_of_extracts',
          }
        : {},
    [withShareOfExtracts]
  );
  if (!modalIsOpen || !categoryDataKey) return null;

  return (
    <Modal closeIcon onClose={onClose} open={modalIsOpen} size="large">
      <Modal.Header content={title} />
      {data !== undefined ? (
        <Modal.Content
          style={{
            height: '65vh',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
          }}
        >
          <BreadcrumbContainer>
            <Breadcrumb
              fontSize={svars.fontSizeLarge}
              onClick={onSelectConcept}
              path={path}
              compact
              inline
              style={{
                padding: `${svars.spaceNormalLarge} ${svars.spaceMedium}`,
              }}
              tailElement={
                <InTextDropdown
                  key="tail-bc"
                  options={selectItems}
                  value={currentDistribution}
                  onChange={onDistributionChange}
                />
              }
            />
          </BreadcrumbContainer>
          <BodyContainer>
            <ButtonGroup
              as={SecondaryTabButton}
              items={[
                {
                  key: 'graph',
                  i18nLabel: t`graphical-view`,
                  value: 'graph',
                },
                { key: 'table', i18nLabel: t`table-view`, value: 'table' },
              ]}
              onChange={(_, { value }) => setExplorationMode(value)}
              value={explorationMode}
            />
            <NormalSpacePaddedSegment
              style={{
                padding: svars.spaceNormalLarge,
                overflow: 'auto',
                flexGrow: 1,
              }}
            >
              {explorationMode === 'graph' ? (
                <>
                  <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <ExportAsImage
                      style={{
                        float: 'right',
                        marginTop: svars.spaceNormalLarge,
                        marginRight: svars.spaceNormalLarge,
                      }}
                      tooltipPosition="top left"
                      onClick={toggleExportModal}
                    />
                  </div>
                  <ScatterChartWithExportModal
                    data={data}
                    chartId={chartId}
                    categoryDataKey={categoryDataKey}
                    labelFormatter={labelFormatter}
                    height={document.documentElement.clientHeight * 0.4}
                    useSentimentColor
                    modalIsOpen={exportModalIsOpen}
                    onCloseModal={toggleExportModal}
                    exportName={exportName}
                    {...shareOfExtractsProps}
                  />
                </>
              ) : null}
              {explorationMode === 'table' ? (
                <ExplorationBreakdownTable
                  withShareOfExtracts={withShareOfExtracts}
                  categoryDataKey={categoryDataKey}
                  categoryHeader={categoryHeader}
                  data={data}
                  labelFormatter={labelFormatter}
                  globalAverageSentiment={globalAverageSentiment}
                  exportName={exportName}
                />
              ) : null}
            </NormalSpacePaddedSegment>
          </BodyContainer>
        </Modal.Content>
      ) : (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      )}
      <Modal.Actions>
        <ButtonTransparentAccent type="submit" onClick={onClose}>
          <Trans id="close" />
        </ButtonTransparentAccent>
      </Modal.Actions>
    </Modal>
  );
}

ExplorationBreakdownModal.propTypes = {
  categoryDataKey: PropTypes.string,
  data: PropTypes.arrayOf(commonPropTypes.productHierarchyDistributionItem),
  onClose: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
  labelFormatter: PropTypes.func,
  title: PropTypes.string,
  globalAverageSentiment: PropTypes.number,
  path: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node,
    })
  ),
  withShareOfExtracts: PropTypes.bool,
};

ExplorationBreakdownModal.defaultProps = {
  categoryDataKey: null,
  modalIsOpen: false,
  title: null,
  labelFormatter: null,
  globalAverageSentiment: undefined,
  data: null,
  path: [
    {
      component: (
        <span key="bad-global">
          <Trans render={capitalizedTranslation} id="global" />
        </span>
      ),
    },
  ],
  withShareOfExtracts: false,
};

export const DISTRIBUTION_STATIC_DIMENSIONS = [
  {
    key: 'product_hierarchy',
    value: 'product_hierarchy',
    text: t`product-service`,
  },
  { key: 'source', value: 'source', text: t`source` },
];

function ExplorationBreakdownButtonWithModal({
  data,
  disabled,
  onDistributionChange,
  ...props
}) {
  const tagSetItems = useSelector(analysisTagSetsItemsSelector);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const toggleOpen = useCallback(
    () => setDropdownIsOpen(!dropdownIsOpen),
    [dropdownIsOpen]
  );
  const selectItems = useMemo(() => {
    const items = [];
    DISTRIBUTION_STATIC_DIMENSIONS.forEach((item) => {
      items.push({
        ...item,
        onClick: onDistributionChange,
        text: item.text ? (
          <Trans render={capitalizedTranslation} id={item.text} />
        ) : (
          ''
        ),
      });
    });
    tagSetItems.forEach((item) => {
      items.push({ ...item, onClick: onDistributionChange });
    });
    return items;
  }, [tagSetItems]);
  return (
    <HelpTooltip
      disabled={!disabled}
      help={[t`base-group-contains-only-one-element`]}
      size="tiny"
      position="top center"
      trigger={
        <div style={{ alignSelf: 'flex-end' }}>
          <Popup
            open={dropdownIsOpen}
            flowing
            trigger={
              <div style={{ display: 'inline-flex' }}>
                <AnalyticsAwareButton
                  inputComponent={SecondaryTabButton}
                  content={
                    <Trans render={capitalizedTranslation} id="distributions" />
                  }
                  icon="chevron down"
                  labelPosition="right"
                  opened={dropdownIsOpen ? 'true' : null}
                  disabled={disabled}
                  onClick={toggleOpen}
                />
              </div>
            }
            content={
              // Trash component when display is toggled, to empty the dropdown state between usages ?
              <Menu vertical>
                <StyledMenuHeader>
                  <Trans id="select-a-dimension" />:
                </StyledMenuHeader>
                {selectItems.map(({ value, key, text }) => (
                  <Menu.Item
                    key={key}
                    name={key}
                    value={value}
                    onClick={onDistributionChange}
                  >
                    {text}
                  </Menu.Item>
                ))}
              </Menu>
            }
            on="click"
            hoverable
            onClose={toggleOpen}
            onOpen={toggleOpen}
            position="bottom right"
            style={{ zIndex: 900, padding: 0 }}
          />
          <ExplorationBreakdownModal
            selectItems={selectItems}
            {...props}
            data={data}
          />
        </div>
      }
    />
  );
}

ExplorationBreakdownButtonWithModal.propTypes = {
  ...ExplorationBreakdownModal.propTypes,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
};
ExplorationBreakdownButtonWithModal.defaultProps = {
  ...ExplorationBreakdownModal.defaultProps,
  disabled: false,
  primary: false,
};

export { ExplorationBreakdownButtonWithModal };
export default ExplorationBreakdownModal;
