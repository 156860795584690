import React from 'react';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import { toggleSupportModal } from 'actions/ui';

import { RouterLink } from 'components/ui/Link';
import { ButtonTransparentAccent } from 'components/ui/button';

import config from 'config';

function SupportModal({ supportModalOpen, onToggleSupportModal }) {
  return (
    <Modal
      centered={false}
      style={{ top: '35%' }}
      open={supportModalOpen}
      onClose={onToggleSupportModal}
    >
      <Modal.Header icon="help" content="Support" />
      <Modal.Content>
        <p>
          <Trans>
            Pour obtenir de l&apos;aide, veuillez envoyer un mail à
            l&apos;adresse :{' '}
            <RouterLink href={`mailto:${config.supportEmail}`}>
              {config.supportEmail}
            </RouterLink>
          </Trans>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentAccent onClick={onToggleSupportModal}>
          <Trans id="close" />
        </ButtonTransparentAccent>
      </Modal.Actions>
    </Modal>
  );
}

SupportModal.propTypes = {
  supportModalOpen: PropTypes.bool.isRequired,
  onToggleSupportModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({ supportModalOpen: state.ui.supportModalOpen }),
  (dispatch) => ({ onToggleSupportModal: () => dispatch(toggleSupportModal()) })
)(SupportModal);
