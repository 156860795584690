import React, { Component } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid, Icon, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import ClickableTagCloud from 'components/ui/ClickableTagCloud';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import { LargeSpacePaddedRow, MediumSpacePaddedRow } from 'components/ui/Grid';
import Header from 'components/ui/Header';
import Link, { RouterLinkV2 } from 'components/ui/Link';
import Segment from 'components/ui/Segment';
import { AnalyticsAwareButton } from 'components/ui/button';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { PrimaryTabButton } from 'components/ui/button/TabButton';
import emptyWordcloudDataUrl from 'components/ui/svg/undraw_blank_canvas_3rbb.svg';
import emptyDataUrl from 'components/ui/svg/undraw_empty_xct9.svg';
import noSelectionDataUrl from 'components/ui/svg/undraw_opened_tabs_ly11.svg';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import Statistics from 'components/ui/visualization/Statistics';

import { scrollToTop } from 'utils/browser';
import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const TAGCLOUD_POLARITIES = [
  {
    key: 'all',
    i18nLabel: t`positive-and-negative-points`,
    value: 'all',
    sentimentRange: [-1.0, 1.0],
  },
  {
    key: 'positive',
    i18nLabel: t`positive-points`,
    value: 'positive',
    sentimentRange: [0, 1.0],
  },
  {
    key: 'negative',
    i18nLabel: t`negative-points`,
    value: 'negative',
    sentimentRange: [-1.0, 0],
  },
];

const getPolarityField = (wordcloudPolarity, field) => {
  if (!wordcloudPolarity) {
    return null;
  }
  return TAGCLOUD_POLARITIES.find(({ value }) => value === wordcloudPolarity)[
    field
  ];
};

function UnselectedConceptEmptyCase() {
  return (
    <Segment>
      <EmptyDataPage
        i18nHeaderText={t`dashboard-explore.select-a-category-first`}
        actionComponent={
          <span>
            <Trans>
              Naviguez à travers la hiérarchie des catégories{' '}
              <Link base="true" onClick={scrollToTop}>
                en haut de page
              </Link>
              .
              <br />
              Analysez ici le détail des catégories.
            </Trans>
          </span>
        }
        illustrationUrl={emptyDataUrl}
      />
    </Segment>
  );
}

UnselectedConceptEmptyCase.propTypes = {};

function WordCloudEmptyCase({
  wordcloudPolarity,
  label,
  getSearchRouteParameters,
}) {
  const polarityLabel = wordcloudPolarity
    ? getPolarityField(wordcloudPolarity, 'i18nLabel')
    : t`positive-or-negative-points`;
  return (
    <Segment>
      <EmptyDataPage
        layout="row"
        headerText={<Trans>Pas assez de {i18n._(polarityLabel)}</Trans>}
        actionComponent={
          <span>
            <Trans id={t`dashboard-explore.empty-wordcloud-details`} />:
            <div style={{ paddingTop: svars.spaceNormal }}>
              <b>&laquo;{label}&raquo;</b>.
            </div>
            <br />
            <Trans>
              Accéder directement{' '}
              <RouterLinkV2
                base="true"
                to={getSearchRouteParameters([], wordcloudPolarity || 'all')}
              >
                aux extraits
              </RouterLinkV2>
            </Trans>
            .
          </span>
        }
        illustrationUrl={emptyWordcloudDataUrl}
      />
    </Segment>
  );
}

WordCloudEmptyCase.propTypes = {
  wordcloudPolarity: PropTypes.string,
  label: PropTypes.string.isRequired,
  getSearchRouteParameters: PropTypes.func.isRequired,
};
WordCloudEmptyCase.defaultProps = { wordcloudPolarity: null };

function WordDetailsEmptyCase() {
  return (
    <EmptyDataPage
      i18nHeaderText={t`dashboard-explore.click-on-term-to-see-details`}
      actionComponent={
        <span>
          <p>
            <Trans id="dashboard-explore.wordcloud-size-description" />
            .
            <br />
            <Trans id="dashboard-explore.wordcloud-color-description" />.
          </p>
        </span>
      }
      illustrationUrl={noSelectionDataUrl}
      maxHeight="12rem"
    />
  );
}

WordDetailsEmptyCase.propTypes = {};

const ClickableRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${svars.colorLightGrey};
  &:hover {
    ${svars.hoverClickableCss}
  }
`;
class ConceptDrilldown extends Component {
  componentDidUpdate(prevProps) {
    const { onSelectedWordChange, selectedConceptItem } = this.props;
    if (prevProps.selectedConceptItem !== selectedConceptItem) {
      onSelectedWordChange(null);
    }
  }

  renderWordcloud() {
    const {
      selectedConceptItem,
      wordClouds,
      wordcloudsIsLoading,
      onSelectedWordChange,
      wordcloudPolarity,
      selectedWord,
    } = this.props;

    const vocabulary =
      (wordClouds[selectedConceptItem.id] &&
        wordClouds[selectedConceptItem.id].vocabulary[wordcloudPolarity]) ||
      null;
    return (
      <ClickableTagCloud
        sentimentRange={getPolarityField(wordcloudPolarity, 'sentimentRange')}
        className="tag-cloud"
        data={vocabulary}
        style={{
          fontFamily: svars.fontFamilySansSerif,
          padding: 5.5,
        }}
        containerStyle={{
          padding: 0.5,
          height: '100%',
          width: '100%',
        }}
        onWordClick={onSelectedWordChange}
        selectedWord={selectedWord}
        loading={wordcloudsIsLoading}
        chartTitle={`${selectedConceptItem?.label} - ${capitalize(
          t`wordcloud`
        )}`}
        showScale
        exportable
      />
    );
  }

  renderCooccurrences() {
    const { getSearchRouteParameters, selectedWord, wordcloudPolarity } =
      this.props;
    if (!selectedWord?.cooccurrence?.length) {
      return <EmptyDataVisualization />;
    }
    return (
      <Table
        style={{
          display: 'block',
          width: '100%',
          height: '0px',
          overflowY: 'auto',
          flex: '1 1 auto',
          margin: 0,
        }}
      >
        <Table.Body style={{ display: 'inline-table', width: '100%' }}>
          {selectedWord?.cooccurrence?.map(({ text, n_chunks, sentiment }) => (
            <ReactRouterLink
              base="true"
              to={getSearchRouteParameters(
                [selectedWord?.text, text],
                wordcloudPolarity
              )}
              key={`selected-word-${text}`}
            >
              <ClickableRow key={`cooc-word-${text}`}>
                <Table.Cell width={9} verticalAlign="middle">
                  {selectedWord?.text} + {text}
                </Table.Cell>
                <Table.Cell width={5} verticalAlign="middle">
                  <Statistics nChunks={n_chunks} averageSentiment={sentiment} />
                </Table.Cell>
                <Table.Cell
                  verticalAlign="middle"
                  width={1}
                  style={{
                    paddingRight: svars.spaceNormal,
                  }}
                >
                  <Icon name="chevron right" />
                </Table.Cell>
              </ClickableRow>
            </ReactRouterLink>
          ))}
        </Table.Body>
      </Table>
    );
  }

  renderSelectedWordDetails() {
    const { getSearchRouteParameters, selectedWord, wordcloudPolarity } =
      this.props;
    return (
      <Grid style={{ height: '100%' }}>
        <Grid.Row
          as={Segment}
          verticalAlign="middle"
          style={{
            borderRadius: svars.ctaBorderRadius,
            padding: `${svars.spaceSmall} ${svars.spaceMedium}`,
          }}
        >
          <Grid.Column
            width={16}
            style={{ wordBreak: 'break-word', paddingLeft: 0 }}
          >
            <p
              style={{
                fontSize: svars.fontSizeXLarge,
                fontWeight: svars.fontWeightBold,
                padding: svars.spaceNormal,
              }}
            >
              &laquo;
              {selectedWord?.text}
              &raquo;
            </p>
          </Grid.Column>
          <Grid.Column width={5} floated="right">
            <Statistics
              nChunks={selectedWord?.n_chunks}
              averageSentiment={selectedWord?.sentiment}
              style={{ paddingBottom: svars.spaceNormal }}
            />
          </Grid.Column>
          <Grid.Column style={{ paddingRight: 0 }} width={6} textAlign="right">
            <RouterLinkV2
              to={getSearchRouteParameters(
                [selectedWord?.text],
                wordcloudPolarity
              )}
              key={`selected-word-${selectedWord?.text}`}
            >
              <AnalyticsAwareButton
                inputComponent={PrimaryTabButton}
                gaCategory="Dashboard - Explore"
                gaAction="Navigate to concept word search"
                gaLabel={wordcloudPolarity}
                style={{
                  padding: `${svars.spaceNormal} ${svars.spaceMedium}`,
                }}
              >
                <Icon name="search" />
                <Trans id="dashboard-explore.see-extracts" />
              </AnalyticsAwareButton>
            </RouterLinkV2>
          </Grid.Column>
        </Grid.Row>
        <MediumSpacePaddedRow style={{ minHeight: '77%' }}>
          <Grid.Column
            width={16}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              flexGrow: 1,
            }}
          >
            <Header style={{ padddingBottom: 0 }}>
              <Trans id="cooccurrences" />
            </Header>
            {this.renderCooccurrences()}
          </Grid.Column>
        </MediumSpacePaddedRow>
      </Grid>
    );
  }

  render() {
    const {
      selectedConceptItem,
      wordClouds,
      getSearchRouteParameters,
      selectedWord,
      wordcloudPolarity,
      onWordcloudPolarityChange,
    } = this.props;
    const conceptIsSelected = !!selectedConceptItem;
    let wordcloudsAreEmpty = false;
    let selectedWordcloudIsEmpty = false;
    if (selectedConceptItem && wordClouds[selectedConceptItem.id]) {
      if (
        Object.values(wordClouds[selectedConceptItem.id].vocabulary)
          .map((terms) => terms.length)
          .reduce((sum, x) => sum + x, 0) < 3
      ) {
        wordcloudsAreEmpty = true;
      }
      if (
        !wordClouds[selectedConceptItem.id].vocabulary[wordcloudPolarity].length
      ) {
        selectedWordcloudIsEmpty = true;
      }
    }
    return (
      <>
        <LargeSpacePaddedRow
          style={{ paddingBottom: 0, paddingTop: svars.spaceMedium }}
          columns={3}
        >
          <ButtonGroup
            as={PrimaryTabButton}
            items={TAGCLOUD_POLARITIES}
            onChange={onWordcloudPolarityChange}
            value={wordcloudPolarity}
            disabled={!conceptIsSelected || wordcloudsAreEmpty}
          />
        </LargeSpacePaddedRow>
        <LargeSpacePaddedRow
          style={{
            height: '480px',
            paddingTop: svars.spaceMedium,
          }}
        >
          {conceptIsSelected &&
            !wordcloudsAreEmpty &&
            !selectedWordcloudIsEmpty && (
              <>
                <Grid.Column width={9} style={{ height: '100%' }}>
                  <Segment
                    style={{
                      overflow: 'hidden',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    {this.renderWordcloud()}
                  </Segment>
                </Grid.Column>
                <Grid.Column width={7} style={{ height: '100%', padding: 0 }}>
                  <Segment
                    style={{
                      height: '100%',
                      marginLeft: svars.spaceMedium,
                      padding: 0,
                    }}
                  >
                    {selectedWord ? (
                      this.renderSelectedWordDetails()
                    ) : (
                      <WordDetailsEmptyCase />
                    )}
                  </Segment>
                </Grid.Column>
              </>
            )}
          {!conceptIsSelected && (
            <Grid.Column width={16} stretched>
              <UnselectedConceptEmptyCase />
            </Grid.Column>
          )}
          {(wordcloudsAreEmpty || selectedWordcloudIsEmpty) && (
            <Grid.Column width={16} stretched>
              <WordCloudEmptyCase
                wordcloudPolarity={
                  !wordcloudsAreEmpty ? wordcloudPolarity : null
                }
                label={selectedConceptItem?.label}
                getSearchRouteParameters={getSearchRouteParameters}
              />
            </Grid.Column>
          )}
        </LargeSpacePaddedRow>
      </>
    );
  }
}

ConceptDrilldown.propTypes = {
  wordClouds: commonPropTypes.wordClouds.isRequired,
  wordcloudsIsLoading: PropTypes.bool.isRequired,
  getSearchRouteParameters: PropTypes.func.isRequired,
  selectedConceptItem: commonPropTypes.ontologyItem,
  wordcloudPolarity: PropTypes.string.isRequired,
  selectedWord: PropTypes.shape({
    text: PropTypes.string.isRequired,
    n_chunks: PropTypes.number.isRequired,
    sentiment: PropTypes.number.isRequired,
    cooccurrence: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        n_chunks: PropTypes.number.isRequired,
        sentiment: PropTypes.number.isRequired,
      })
    ),
  }),
  onSelectedWordChange: PropTypes.func.isRequired,
  onWordcloudPolarityChange: PropTypes.func.isRequired,
};
ConceptDrilldown.defaultProps = {
  selectedConceptItem: null,
  selectedWord: null,
};

export default ConceptDrilldown;
